const Translate = () => {

    function translate_page(el) {
        var evObj = document.createEvent("HTMLEvents");

        evObj.initEvent("change", false, false);
        el.dispatchEvent(evObj);
    }
    function changeEvent(el, language) {
        if (el.fireEvent) {
            el.fireEvent('onchange');
        } else {
            translate_page(el);

            if (!el.value) {
                const forceValue = setInterval(() => {
                    el.value = language;

                    if (el.value) {

                        translate_page(el);
                        clearInterval(forceValue);
                    }
                }, 500);
            }
        }
    }


    function change_language(language, google_tradutor) {
        if (google_tradutor) {
            google_tradutor.value = language;
            localStorage.setItem('language-abbreviation', `${language}`);
            changeEvent(google_tradutor, language);
        }
    }


    function addEvent(SelectsLang, google_tradutor) {
        SelectsLang.forEach((selectlang) => {
            selectlang.addEventListener("click", (e) => {
                const text = e.target.innerText;
                change_language(text.toLowerCase(), google_tradutor);
            });
        });
    }

    function googleTranslateElementInit(pageLang, optionLang) {
        if (!localStorage.getItem('language-abbreviation')) {
            localStorage.setItem('language-abbreviation', 'pt');
        }

        let timer = setInterval(() => {
            let google_script_url = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            let script = document.querySelector("script[src='" + google_script_url + "']");
            console.log(script)
            if (script) {
                new google.translate.TranslateElement({
                    pageLanguage: `${pageLang}`,
                    includedLanguages: `${optionLang}`,
                    layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                    autoDisplay: false, // Desativa o popup de tradução automática
                }, 'google_translate_element');
                

                const google_tradutor = document.getElementById("google_translate_element").querySelector(".goog-te-combo");

                const classSelectLang = 'box-lang';
                const SelectsLang = document.querySelectorAll(`.${classSelectLang} li`);

                addEvent(SelectsLang, google_tradutor);
                change_language(localStorage.getItem('language-abbreviation', google_tradutor));

                clearInterval(timer);

                return (google_tradutor);


            } else {
                console.log('erro')
            }
        }, 2000);
    }

    function main() {
        let google_tradutor = googleTranslateElementInit('pt', 'pt,en');

    }


    document.addEventListener("DOMContentLoaded", () => {
        main();
    });
}

export default Translate;