export default function mottaReconhecimento() {
    const pageReconhecimento = document.querySelector('#premios-reconhecimentos')
    const itemList = document.querySelectorAll('.item-lista')
    const contentActive = document.querySelectorAll('.tab-content')

    const url = window.location.href
    let filtroUrl = url.includes('?page')

    if(filtroUrl && pageReconhecimento){
        itemList[0].classList.remove('ativo')
        itemList[1].classList.add('ativo')

        contentActive[0].classList.remove('ativo')
        contentActive[1].classList.add('ativo')
        console.log(filtroUrl);
    }
}    

/*
itemList[0].classList.remove('ativo')
itemList[1].classList.add('ativo')

contentActive[0].classList.remove('ativo')
contentActive[1].classList.add('ativo')
*/