export default function sobreJs() {
    const items = document.querySelectorAll('.associacoes-lis-item');
  
    function toggleContent(item) {
      const text = item.querySelector('.content');
      const icon = item.querySelector('.icon');
  
      text.classList.toggle('show');
      icon.classList.toggle('active');
    }
  
    function handleClick() {
      const item = this.closest('.associacoes-lis-item');
      toggleContent(item);
    }
  
    items.forEach(item => {
      const icon = item.querySelector('.icon');
      icon.addEventListener('click', handleClick);
    });
  }
  