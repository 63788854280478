export default function mottaAreas() {
  function richTextParse() {
    function parseJSONToHTML(json) {
      const data = JSON.parse(json);

      console.log(data);

      let html = "";

      for (const block of data.blocks) {
        switch (block.type) {
          case "paragraph":
            html += `<p class="paragraph">${block.data.text}</p>`;
            break;
          case "Header":
            html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
            break;
          case "Table":
            html += `<table class="table">${block.data.content}</table>`;
            break;
          case "Raw":
            html += `<div class="raw">${block.data.html}</div>`;
            break;
          case "Delimiter":
            html += '<hr class="delimiter">';
            break;
          case "Code":
            html += `<pre class="code"><code>${block.data.code}</code></pre>`;
            break;
          case "Quote":
            html += `<blockquote class="quote">${block.data.text}</blockquote>`;
            break;
          case "List":
            const listItems = block.data.items
              .map((item) => `<li>${item}</li>`)
              .join("");
            const listStyle = block.data.style === "ordered" ? "ol" : "ul";
            html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
            break;
          case "Embed":
            html += `<div class="embed ${block.data.service}">
								<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								</div>`;
            break;
          case "Checklist":
            const checklistItems = block.data.items
              .map((item) => {
                const checked = item.checked ? "checked" : "";
                return `<li>
									<input  type="checkbox" onclick="return false;"  ${checked}>
									<label> ${item.text} </label>
									</li>`;
              })
              .join("");
            html += `<ul>${checklistItems}</ul>`;
            break;
          case "Image":
            html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
            break;
          case "Warning":
            html += `<div class="warning">
															<h3>${block.data.title}</h3>
															<p>${block.data.message}</p>
															</div>`;
            break;
          default:
            // Handle unknown block types here
            break;
        }
      }

      return html;
    }

    const blogContent = document.querySelectorAll(".blog-content");
    let inputs = document.querySelectorAll(".form-content");
    let texts = document.querySelectorAll(".content-area");
    const selectTab = document.querySelector(".select-wrapper select");

    function showContent(tabId) {
      texts.forEach((item) => item.classList.remove("active"));

      const currentContent = document.querySelector(`.${tabId}`);
      if (currentContent) {
        currentContent.classList.add("active");
      }
    }

    inputs.forEach((item, index) => {
      item.addEventListener("click", (e) => {
        const tab = e.target.closest(".form-content");
        const tabId = tab.dataset.tabContent;
        showContent(tabId);
      });
    });

    selectTab.addEventListener("change", (e) => {
      const tabId = selectTab.value;
      if (tabId) {
        showContent(tabId);
      }
    });

    blogContent.forEach((blog) => {
      if (blog.dataset.conteudo) {
        const htmlParsed = parseJSONToHTML(blog.dataset.conteudo);
        console.log(htmlParsed);
        blog.innerHTML = htmlParsed;
      } else {
        console.log("SEM CONTEUDO ;");
      }
    });
  }

  richTextParse();
}
