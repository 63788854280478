export default function mottaNewslleter() {
    const contatoFormSubmit = document.querySelector('.form-newsletter')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            email: document.querySelector('#email').value,  
        }
        const url = "/cadastro_newsletter/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector(".form-newsletter .btn-newslleter")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.style.opacity = "0.5"
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.style.opacity = "1.0"
    }
}