export default function mottaPremios() {
    const tabPremio = document.querySelectorAll(".item-lista");
    const tabConteudo = document.querySelectorAll('.tab-content')
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get('data');
    console.log(dataParam);
    if (dataParam || dataParam == "") {
        tabPremio.forEach(i => i.classList.remove('ativo'));
        tabConteudo.forEach(i => i.classList.remove('ativo'));
        tabConteudo[1].classList.add('ativo');
        tabPremio[1].classList.add('ativo');
    }
}