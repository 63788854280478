const SelectConfig = (classSelect) => {


    function construct_li(src, paragraph) {
        return (`
          <object style="point-events: none;">
              <li>
                  <img src="${src}" alt="" />
                  <p>
                      <span>${paragraph}</span>
                  </p>
              </li>
          </object>
      `);
    }

    function construct_ul(allOptions, classUL) {

        let LIs = '';
        allOptions.forEach(option => {
            LIs += `
              ${construct_li(option.attributes["data-icone"].value, option.value)}
          `
        });

        const UL = `<ul class="${classUL}">
          ${LIs}
      </ul>`;

        return UL;
    }


    function construct_element_select(Selects, option, UL, className) {
        Selects.forEach((Select, indice) => {
            const daddySelect = Select.parentElement;

            daddySelect.innerHTML += `
              <div class='${className} ${className}-${indice + 1}'>
                  <div class="select-option">
                      <img src="${option.attributes["data-icone"].value}" alt="" />
                      <p>
                          ${option.innerHTML}
                      </p>
                      <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.64866 5.33482C4.56921 5.4231 4.43079 5.4231 4.35134 5.33482L0.300414 0.833793C0.18458 0.705089 0.275918 0.5 0.449072 0.5L8.55093 0.5C8.72408 0.5 8.81542 0.705088 8.69959 0.833793L4.64866 5.33482Z" fill="#6BB6EF"/>
                      </svg>
                  </div>
                  ${UL}
              </div>
          `
        });
    }

    function get_elements(classSelect) {
        const Select = document.querySelector(`${classSelect}`);
        const obj = new Array();
        for (let i = 0; i < Select.length; i++) {
            obj.push(Select[i]);
        }
        return obj;
    }

    function check_language_selected(options, Selects, UL, className) {
        //const Selects = document.querySelectorAll(`${classSelect}`);
        const waitLocalStorage = setInterval(() => {
            if (localStorage.getItem('language-abbreviation')) {
                options.forEach((option) => {
                    const value_option = option.attributes.value.nodeValue;

                    if (value_option.toLowerCase() === localStorage.getItem('language-abbreviation')) {
                        Selects.forEach((select) => {
                            select.value = value_option;
                        });
                        construct_element_select(Selects, option, UL, className);
                    }
                })

                clearInterval(waitLocalStorage);
            }
        }, 2000)
    }

    function make_ul(classSelect, UL, options, className) {
        const Selects = document.querySelectorAll(`${classSelect}`);
        check_language_selected(options, Selects, UL, className);

    }

    function toogleEventSelect(box, classUL, select_option) {
        const SelectOption = document.querySelector(`.${box} .${select_option}`);
        const SVG = document.querySelector(`.${box} .${select_option} svg`);
        const UL = document.querySelector(`.${box} .${classUL}`);

        SelectOption && SelectOption.addEventListener("click", () => {
            UL.classList.toggle('active');
            SVG.classList.toggle('active');

        });
    }

    function handleClickOption(box, classUL, select_option, classSelect) {
        const Options = document.querySelectorAll(`.${box} .${classUL} li`);
        const UL = document.querySelector(`.${box} .${classUL}`);
        const SVG = document.querySelector(`.${box} .${select_option} svg`);
        const Selects = document.querySelectorAll(`${classSelect}`);

        const Select_Option = document.querySelector(`.${box} .${select_option}`);
        if (Select_Option) {
            const SRCImg = Select_Option.children[0].attributes.src;
            const Text = Select_Option.children[1];


            Options.forEach((option) => {
                option.addEventListener("click", (e) => {

                    //console.log(e);
                    const srcImgItem = e.target.children[0].attributes.src.value;
                    const textItem = e.target.children[1].innerText;

                    SRCImg.nodeValue = srcImgItem;
                    Text.innerText = textItem;

                    UL.classList.remove('active');
                    SVG.classList.remove('active');

                    Selects.forEach((select) => {
                        select.value = textItem;
                    });
                });
            });
        }
    }

    //Função principal
    async function main() {
        const BoxName = "box-lang";
        const ULName = "ul_select_lang";
        const SelectOptionName = "select-option";
        const Select = document.querySelector(`${classSelect}`);


        const allOptions = get_elements(classSelect);
        const UL = construct_ul(allOptions, ULName);

        make_ul(classSelect, UL, allOptions, BoxName);

        for (let i = 1; i <= Select.length; i++) {
            const IntervalFor = setInterval(() => {
                if (document.querySelector(`.${BoxName}-${i} .${SelectOptionName}`)) {

                    toogleEventSelect(`${BoxName}-${i}`, ULName, SelectOptionName);
                    handleClickOption(`${BoxName}-${i}`, ULName, SelectOptionName, classSelect);

                    clearInterval(IntervalFor);
                }
            }, 500);
        }
    }

    document.addEventListener("DOMContentLoaded", () => {
        main();
    });
}
export default SelectConfig;