export default function menu() {

    window.addEventListener("scroll", function () {
        const headerEl = document.querySelector("#menu");
        headerEl.classList.toggle("sticky", window.scrollY > 550);
    });

    const btnMobile = document.getElementById('btn-mobile');

    function toggleMenu() {
        const nav = document.getElementById('navigation');
        nav.classList.toggle('active');
    }
    btnMobile.addEventListener('click', toggleMenu);
}