// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js";
import showFormErrors from "./showFormErrors.js";
import menu from "./menu.js";
import homeJs from "./scriptsPaginas/home.js";
import sobreJs from "./scriptsPaginas/sobre.js";
import mottaContato from "./mottaContato.js";
import mottaSobre from "./scriptsPaginas/mottaSobre.js";
import Translate from "./modules/translate.js";
import SelectConfig from "./modules/selectConfig.js";
import mottaNewslleter from "./mottaNewslleter.js";
import mottaPremios from "./scriptsPaginas/mottaPremios.js";
import mottaEquipe from "./scriptsPaginas/mottaEquipe.js";
import mottaAreas from "./scriptsPaginas/mottaAreas.js";
import mottaBannerHome from "./scriptsPaginas/mottaBannerHome.js";
import mottaQuemSomos from "./scriptsPaginas/mottaQuemSomos.js";
import mottaEquipeText from "./scriptsPaginas/mottaEquipeText.js";
import mottaRanking from "./scriptsPaginas/mottaRanking.js";
import mottaReconhecimento from "./scriptsPaginas/mottaReconhecimento.js";

mottaReconhecimento();

mostrarSenha();
showFormErrors();

if (window.AOS) {
  AOS.init({
    disable: function () {
      var maxWidth = 768;
      return window.innerWidth < maxWidth;
    },
    startEvent: "DOMContentLoaded",
    offset: 30, // offset (in px) from the original trigger point
    delay: 800, // values from 0 to 3000, with step 50ms
    duration: 800, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
  });
}

mottaNewslleter();
mottaContato();
mottaSobre();

menu();
SelectConfig(".select-lang");
Translate();

// PAGES
const pageHome = document.querySelector(".body-home");
const pageSobre = document.querySelector(".body-sobre");
const singlePage = document.querySelector(".body-single-page");
const pageEquipe = document.querySelector(".body-equipe");
const pageAreas = document.querySelector(".body-areas-atuacao");
const pageSingleEquipe = document.querySelector(".body-single-equipe");
const pageEquipeDetalhe = document.querySelector("#nossa-equipe-detalhe");

// ★ Pages Scopo
if (pageHome) {
  homeJs();
  mottaBannerHome();
} else if (pageSobre) {
  sobreJs();
  mottaPremios();
  mottaQuemSomos();
} else if (singlePage) {
  const swiperNossoTime = new Swiper(".teste", {
    slidesPerView: 1.2,
    spaceBetween: 50,
    breakpoints: {
      540: {
        slidesPerView: 2,
        spaceBetween: 100,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 80,
      },

      1024: {
        slidesPerView: 2,
        spaceBetween: 200,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
} else if (pageEquipe) {
  mottaEquipe();
} else if (pageEquipeDetalhe) {
  mottaEquipeText();
} else if (pageAreas) {
  mottaAreas();
} else if (pageSingleEquipe) {
  mottaRanking();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);

document.querySelector("body").addEventListener("dblclick", function(event){
  event.preventDefault();
});


