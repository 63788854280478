export default function mottaContato() {
  const contatoFormSubmit = document.querySelector("#form-submit");
  console.log(contatoFormSubmit);
  contatoFormSubmit &&
    contatoFormSubmit.addEventListener("submit", function (event) {
      event.preventDefault();
      disabledBtn();
      // const form_data = {
      //     nome: document.querySelector('#nome').value,
      //     email: document.querySelector('#email').value,
      //     mensagem: document.querySelector('#mensagem').value,
      // }

      const formData = new FormData();
      formData.append(
        "nome",
        document.querySelector("#form-submit input[name=nome]").value
      );
      formData.append(
        "email",
        document.querySelector("#form-submit input[name=email]").value
      );
      // formData.append('telefone', document.querySelector('#form-submit input[name=telefone]').value);
      formData.append(
        "mensagem",
        document.querySelector("#form-submit textarea[name=mensagem]").value
      );
      if (document.querySelector("#form-submit input[name=arquivo]")) {
        formData.append(
          "arquivo",
          document.querySelector("#form-submit input[name=arquivo]").files[0]
        );
      }

      const url = "/fale-conosco/";
      axios
        .post(url, formData)
        .then((response) => showMessage(response))
        .catch((response) => showMessage(response));
    });

  function showMessage(response) {
    const message = response.data ? response.data.message : response.message;
    GLOBAL.showToastify(message);
    contatoFormSubmit.reset();
    setTimeout(enabledBtn, 1000);
  }

  const btnSubmit = document.querySelector(".contato-form .btn");

  function disabledBtn() {
    btnSubmit.disabled = true;
    btnSubmit.style.opacity = "0.5";
  }

  function enabledBtn() {
    btnSubmit.disabled = false;
    btnSubmit.style.opacity = "1.0";
  }

  const fileImage = document.querySelector('input[type="file"]');
  const textLabel = document.querySelector(".select-file");
  fileImage &&
    fileImage.addEventListener("change", () => {
      textLabel.innerText = fileImage.value.slice(12);
    });
}
