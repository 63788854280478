export default function homeJs() {

    // SWIPER
    const swiperNossoTime = new Swiper(".teste", {
        slidesPerView: 1.2,
        spaceBetween: 16,
        breakpoints: {
            540: {
                slidesPerView: 2.2,
                spaceBetween: 16
            },

            768: {
                slidesPerView: 2.5,
                spaceBetween: 16
            },

            1024: {
                slidesPerView: 3.5,
                spaceBetween: 30
            }
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

}