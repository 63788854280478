export default function mottaSobre() {
  const itemsLista = document.querySelectorAll('.item-lista');
  const conteudo = document.querySelectorAll('.tab-content');

  itemsLista.forEach((item, index) => {
    item.addEventListener('click', function (e) {
      e.preventDefault();

      const tab = e.target.closest(".item-lista");
      const tabId = tab.dataset.tabContent;
      itemsLista.forEach((cont) => cont.classList.remove('ativo'));
      tab.classList.add('ativo');
      
      conteudo.forEach((item) => item.classList.remove('ativo'));

      const currentContent = document.querySelector(`.${tabId}`)
      if (currentContent) {
        currentContent.classList.add("ativo")
      }
    });
  });
}
